@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Quicksand', sans-serif;
}

main {
  overflow-y: scroll;
}
