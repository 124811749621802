.subNav {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  z-index: 10;
}

.subNav.open {
  max-height: 11rem; /* Adjust this height based on your design */
}

.navBar {
  z-index: 20;
  transition: transform 0.3s ease-in-out; /* Add transition for sliding effect */
  /* transform: -translateX(0rem); */
}
